export const roles = [
  {
    key: 1,
    tag: 'kepalaSekolah',
    name: 'Kepala Sekolah',
    status: 'active',
  },
  {
    key: 2,
    tag: 'wakasek',
    name: 'Wakil Kepala Sekolah',
    status: 'active',
    children: [
      {
        key: 3,
        tag: 'kesiswaan',
        name: 'Kesiswaan',
      },
      {
        key: 4,
        tag: 'kurikulum',
        name: 'Kurikulum',
      },
    ],
  },
  {
    key: 5,
    tag: 'guruMapel',
    name: 'Guru Mata Pelajaran',
    status: 'inactive',
  },
  {
    key: 6,
    tag: 'waliKelas',
    name: 'Guru Wali Kelas',
    status: 'inactive',
  },
  {
    key: 7,
    tag: 'pembinaEkskul',
    name: 'Pembina Ekstrakurikuler',
    status: 'inactive',
  },
  // {
  //     key: 7,
  //     tag: "pegawai",
  //     name: "Pegawai"
  // },
]
